<template>
  <div class="newDownPc">
    <img class="rightlogo" :src="`${imgUrl}/img/right-logo.png`" />

    <div class="content">
      <div class="content-top">
        <div class="code">
          <div id="qrcode">
            <vue-qr
              :text="url"
              :size="110"
              :margin="0"
              :dotScale="1"
              :logoScale="0.3"
              :correctLevel="3"
            ></vue-qr>
          </div>
        </div>
        <div class="code-title">
          <img :src="`${imgUrl}/img/codetitleimg.png`" width="150" />
        </div>
      </div>
      <div class="content-bottom">
        <img :src="`${imgUrl}/img/text_img.png`" class="contentimg" />
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  name: 'newDownPc',
  components: {
    VueQr
  },
  data() {
    return {
      url: ''
    }
  },
  mounted() {

    // if (localStorage.getItem('code')) {
    //   this.url =
    //     window.location.origin +
    //     '/#/newdowmMobile?' +
    //     localStorage.getItem('code')
    // } else {
    this.url = window.location.origin + '/#/newdowmMobile'
    // }
  }
}
</script>

<style scoped lang="less">
@import url('../assets/url.less');
.newDownPc {
  width: 100%;
  background: url('https://sabbont.gz.bcebos.com/downImg/img/bg.png') no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .rightlogo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
  }
  .content-top {
    display: flex;
    align-items: center;
    justify-content: center;
    .code {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-right: 40px;
      img {
        display: flex !important;
      }
    }
  }

  .contentimg {
    width: 800px;
    margin-top: 40px;
  }
}
</style>
